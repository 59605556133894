:root {
  --border-radius-default: 4px;
  --breakpoint-xs: 320px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1400px;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-finqle: #373a6f;
  --color-primary-100: #e2f3fd;
  --color-primary-200: #c0e8ff;
  --color-primary-300: #9ed8f9;
  --color-primary-400: #4eb6f3;
  --color-primary-500: #0492e5;
  --color-primary-600: #007bc2;
  --color-primary-700: #003d7a;
  --color-primary-800: #00264d;
  --color-primary-dark: #004990; /* deprecated */
  --color-primary-mild: #99d3f5; /* deprecated */
  --color-primary-milddark: #5bacdc; /* deprecated */
  --color-primary-neutral: #9fd7f9; /* deprecated */
  --color-primary-neutrallight: #c7eaff; /* deprecated */
  --color-primary-light: #e2f3fd; /* deprecated */
  --color-primary-lighter: #edf4f9; /* deprecated */
  --color-neutral-100: #f5f7f9;
  --color-neutral-200: #e3e6e8;
  --color-neutral-300: #d4d9dd;
  --color-neutral-400: #b7c1c8;
  --color-neutral-500: #959ea6;
  --color-neutral-600: #6d7781;
  --color-neutral-700: #3d464f;
  --color-neutral-800: #212834;
  --color-positive-200: #e4f2c8;
  --color-positive-300: #c0db8e;
  --color-positive-400: #80ba45;
  --color-positive-500: #4a8a0a;
  --color-positive-600: #3b6e08;
  --color-positive-700: #0f4700;
  --color-negative-200: #ffd8d1;
  --color-negative-300: #ff8e7a;
  --color-negative-400: #f8664c;
  --color-negative-500: #d24025;
  --color-negative-600: #ae2b17;
  --color-negative-700: #690c00;
  --color-warning-200: #ffeee0;
  --color-warning-300: #ffc38f;
  --color-warning-400: #feb95a;
  --color-warning-500: #e7982b;
  --color-warning-600: #df8d1c;
  --color-warning-700: #af5506;
  --color-gray-800: #414153; /* deprecated */
  --color-gray-cool-darker: #b2b7bb; /* deprecated */
  --color-gray-cool-dark: #d4d9dd; /* deprecated */
  --color-gray-cool-mild: #e4e6e8; /* deprecated */
  --color-gray-cool-mildlight: #f3f3f3; /* deprecated */
  --color-gray-cool-neutral: #ebf1f5; /* deprecated */
  --color-gray-cool-light: #f5f8fa; /* deprecated */
  --color-gray-warm-darker: #4b4b5f; /* deprecated */
  --color-gray-warm-dark: #8c8c92; /* deprecated */
  --color-gray-warm-neutral: #a5a5ac; /* deprecated */
  --color-gray-warm-light: #d2d2d6; /* deprecated */
  --color-confirmation-dark: #3f6400; /* deprecated */
  --color-confirmation-neutral: #7fa844; /* deprecated */
  --color-confirmation-light: #c0db8e; /* deprecated */
  --color-notification-dark: #cc6d19; /* deprecated */
  --color-notification-neutral: #dda16d; /* deprecated */
  --color-notification-light: #ffc38f; /* deprecated */
  --color-alert-dark: #690c00; /* deprecated */
  --color-alert-neutral: #dd614a; /* deprecated */
  --color-alert-light: #ff826c; /* deprecated */
  --shadow-default: 0px 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-medium: 0 3px 8px #0000000f;
  --shadow-large: 0px 4px 8px rgba(0, 0, 0, 0.16);
  --space-1: 8px;
  --space-2: 16px;
  --space-3: 24px;
  --space-4: 32px;
  --space-5: 40px;
  --space-0-5: 4px;
  --typography-heading: Remo;
  --typography-body: Lato;
  --typography-family-primary: Lato; /* deprecated */
  --typography-family-secondary: Remo; /* deprecated */
  --typography-size-x-small: 0.75;
  --typography-size-small: 0.85;
  --typography-size-default: 1;
  --typography-size-medium: 1.31;
  --typography-size-x-medium: 1.125;
  --typography-size-large: 1.5;
  --typography-size-x-large: 1.8;
  --typography-size-base: 1; /* the base size of the font */
  --typography-color-default: #4b4b5f;
  --typography-color-mild: #8c8c92;
  --typography-color-darker: #000000;
  --typography-color-light: #d2d2d6;
  --max-width-xl: 1000px;
  --max-width-l: 800px;
  --max-width-m: 600px;
  --max-width-s: 400px;
  --z-index-main-navigation: 1; /* It should be used only for MainNavigation component */
  --z-index-modal: 2; /* It should be used only for modals (Drawer/Sheet/Modal/Dialog). It's the highest z-index possible. */
}
