@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Lato-Normal.woff2) format('woff2'), url(./assets/fonts/Lato-Normal.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/Lato-Bold.woff2) format('woff2'), url(./assets/fonts/Lato-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Lato-NormalItalic.woff2) format('woff2'),
    url(./assets/fonts/Lato-NormalItalic.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/Lato-BoldItalic.woff2) format('woff2'),
    url(./assets/fonts/Lato-BoldItalic.woff) format('woff');
}

@font-face {
  font-family: 'Remo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/RemoPro-Normal.woff2) format('woff2'), url(./assets/fonts/RemoPro-Normal.woff) format('woff');
}

@font-face {
  font-family: 'Remo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/RemoPro-Bold.woff2) format('woff2'), url(./assets/fonts/RemoPro-Bold.woff) format('woff');
}

:root {
  --main-navigation-desktop-width: 240px;
  --main-navigation-mobile-top-bar-height: 64px;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--typography-body), system-ui, 'Segoe UI Variable Display', Roboto, sans-serif;
  background-color: var(--color-neutral-100);
  overscroll-behavior: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
